define("additive-mi/components/ami-dashboard/alerts", ["exports", "@ember/component", "@ember/template-factory", "additive-mi/config/environment", "@glimmer/component", "@glimmer/tracking", "ember-concurrency", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _environment, _component2, _tracking, _emberConcurrency, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-update (perform this.fetchAlerts) this.organizationSlug}}>
    {{#if this._fetchedIntegrityAlertData}}
      <div class="{{@class}}">
        {{#each this._fetchedIntegrityAlertData as |alert|}}
          {{#if (not (eq alert.severity "info"))}}
            <UiInlineNotification
              @classNames="mb3"
              @title={{alert.message}}
              @icon={{if (eq alert.severity "error") "error-state" "info-state"}}
              @isError={{eq alert.severity "error"}}
              @isWarning={{eq alert.severity "warning"}}
            />
          {{/if}}
        {{/each}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "1YxbNyv8",
    "block": "[[[11,0],[4,[38,0],[[28,[37,1],[[30,0,[\"fetchAlerts\"]]],null],[30,0,[\"organizationSlug\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"_fetchedIntegrityAlertData\"]],[[[1,\"    \"],[10,0],[15,0,[29,[[30,1]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"_fetchedIntegrityAlertData\"]]],null]],null],null,[[[41,[28,[37,5],[[28,[37,6],[[30,2,[\"severity\"]],\"info\"],null]],null],[[[1,\"          \"],[8,[39,7],null,[[\"@classNames\",\"@title\",\"@icon\",\"@isError\",\"@isWarning\"],[\"mb3\",[30,2,[\"message\"]],[52,[28,[37,6],[[30,2,[\"severity\"]],\"error\"],null],\"error-state\",\"info-state\"],[28,[37,6],[[30,2,[\"severity\"]],\"error\"],null],[28,[37,6],[[30,2,[\"severity\"]],\"warning\"],null]]],null],[1,\"\\n\"]],[]],null]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@class\",\"alert\"],false,[\"did-update\",\"perform\",\"if\",\"each\",\"-track-array\",\"not\",\"eq\",\"ui-inline-notification\"]]",
    "moduleName": "additive-mi/components/ami-dashboard/alerts.hbs",
    "isStrictMode": false
  });
  /**
   * Component that fetches and shows alerts
   *
   * @class ami-dashboard/alerts
   */
  let AmiDashboardAlerts = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberConcurrency.task)(function* () {
    this._fetchedIntegrityAlertData = null;
    try {
      const url = `${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/alerts`;
      const response = yield this.authenticatedFetch.fetch(url, {
        method: 'GET'
      });
      const json = yield response.json();
      this._fetchedIntegrityAlertData = json;
      this._fetchedIntegrityAlertData.forEach(alert => {
        alert.message = alert.message.replace(/{{date}}/g, alert.date);
        alert.message = alert.message.replace(/{{time}}/g, alert.time);
      });
    } catch (e) {
      /* We only show alerts if provided by the API.
       * If this request fails, it's nothing that a user should worry about and it should be
       * sufficient if the API knows about the incident.
       */
    }
  }), _class = class AmiDashboardAlerts extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      /**
       * the current organization slug
       *
       * @argument organizationSlug
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "organizationSlug", _descriptor3, this);
      /**
       * the fetched integrity alerts
       *
       * @argument _fetchedIntegrityAlertData
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "_fetchedIntegrityAlertData", _descriptor4, this);
      _initializerDefineProperty(this, "fetchAlerts", _descriptor5, this);
      this.fetchAlerts.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_fetchedIntegrityAlertData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fetchAlerts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiDashboardAlerts);
});